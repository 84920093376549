"use strict";

function _createForOfIteratorHelper(o) {
  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) {
      var i = 0;
      var F = function F() {};
      return {
        s: F,
        n: function n() {
          if (i >= o.length) return { done: true };
          return { done: false, value: o[i++] };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F,
      };
    }
    throw new TypeError(
      "Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
    );
  }
  var it,
    normalCompletion = true,
    didErr = false,
    err;
  return {
    s: function s() {
      it = o[Symbol.iterator]();
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it.return != null) it.return();
      } finally {
        if (didErr) throw err;
      }
    },
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }
  return arr2;
}

jQuery(document).ready(function ($) {
  /*---------------------------------- INTERNAL PAGES ----------------------------------*/
  $(".footer-widget-3").addClass("prefix-20");

  $(".intext-highlight").attr("data-aos", "fade-up");

  $("#globe-popup").css(
    "height",
    $(window).height() -
      ($("#masthead").height() + $("#secondary-navigation").height())
  );

  // Allow image maps Resizeable on Locations in Au, NPT, Taxi and etc. pages
  objectFitImages();

  // Set the height of images the same as its width
  function setHeightSameAsWidth() {
    $(
      ".column-img-text .wpb_wrapper :not(a) > img, .other-content > a > img, .search article .post-image > a >img, .home .news-section .posts-container .post img"
    ).height(function () {
      return $(this).width();
    });
  }
  setHeightSameAsWidth();

  // Set VW without horizontal scrollbar
  setVw();
  function setVw() {
    var vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));
  }

  window.addEventListener("resize", function () {
    setVw();
    setHeightSameAsWidth();
  });

  // If Internet Explorer, provide fallback to image maps
  checkIfIE();
  function checkIfIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      $("#map-au-inner-container").html(
        "<img alt='Location-in-Australia-map-fallback' src='/wp-content/uploads/Location-in-Australia-map-fallback.png'>"
      );
      $("#taxi-map-inner-container").html(
        "<img alt='taxi-map-fallback' src='/wp-content/uploads/taxi-map-fallback.png'>"
      );
      $("#npt-map-inner-container").html(
        "<img alt='NPT-map-fallback' src='/wp-content/uploads/NPT-map-fallback.png'>"
      );
      $("#timeline-container").hide();
      $(".page-id-62 #content").css("paddingTop", "40px");
    }

    return false;
  }

  /*---------------------------------- AOS ANIMATION ----------------------------------*/

  AOS.init({
    // Global settings:
    disable: false,
    // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: "DOMContentLoaded",
    // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init",
    // class applied after initialization
    animatedClassName: "aos-animate",
    // class applied on animation
    useClassNames: false,
    // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false,
    // disables automatic mutations' detections (advanced)
    debounceDelay: 50,
    // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99,
    // the delay on throttle used while scrolling the page (advanced)
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 150,
    // offset (in px) from the original trigger point
    delay: 100,
    // values from 0 to 3000, with step 50ms
    duration: 300,
    // values from 0 to 3000, with step 50ms
    easing: "ease",
    // default easing for AOS animations
    once: true,
    // whether animation should happen only once - while scrolling down
    mirror: false,
    // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
  });

  /*---------------------------------- MAIN MENU ----------------------------------*/

  $("#primary-menu ul#menu-primary-menu > li.menu-item-has-children").hover(
    function () {
      $(this).toggleClass("focus");
      $(".site-header").toggleClass("sub-menu-open");
    }
  ); // $('#primary-menu .menu-item.mega-menu ul.sub-menu').wrapInner('<div class="grid-container grid-parent"></div>');

  /*---------------------------------- GLOBAL OFFICE POPUP ----------------------------------*/

  $(".global-offices-popup-trigger").click(function (e) {
    e.preventDefault();
    $("#globe-popup").fadeIn("slow", "swing");
    $("#globe-popup").offset({
      top: $("#masthead").height() + $("#secondary-navigation").height(),
    });
    setTimeout(function () {
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }, 100);
  });
  $("#globe-popup .close-button").click(function () {
    $("#globe-popup").fadeOut("slow", "swing");
  });
  $(".countries .button").click(function () {
    let globeGridContainerDiv = $(this).parent().parent(),
      clickedButton = this;
    if ($(clickedButton).hasClass("active")) {
      $(globeGridContainerDiv)
        .find(".country-information")
        .fadeOut(200, function () {
          $(globeGridContainerDiv)
            .find(".globe-container")
            .removeClass("country-selected");
          $(globeGridContainerDiv)
            .find(".country-information")
            .removeClass("country-selected");
          $(clickedButton).removeClass("active");
          $(globeGridContainerDiv)
            .find(".country-information h3.country-name")
            .html("");
          $(globeGridContainerDiv)
            .find(".country-information .description")
            .html("");
          $(globeGridContainerDiv)
            .find(".country-information > a")
            .attr("href", "#");
        });
    } else {
      if (
        !$(globeGridContainerDiv)
          .find(".country-information")
          .hasClass("country-selected")
      ) {
        showCountryInformation(globeGridContainerDiv, clickedButton);
      } else {
        $(globeGridContainerDiv)
          .find(".country-information")
          .fadeOut(200, function () {
            showCountryInformation(globeGridContainerDiv, clickedButton);
          });
      }
    }
  });

  function showCountryInformation(globeGridContainerDiv, clickedButton) {
    $(globeGridContainerDiv)
      .find(".globe-container")
      .addClass("country-selected");
    $(globeGridContainerDiv)
      .find(".country-information")
      .addClass("country-selected");
    $(globeGridContainerDiv).find(".countries .button").removeClass("active");
    $(clickedButton).addClass("active");
    $(globeGridContainerDiv)
      .find(".country-information h3.country-name")
      .html($(clickedButton).attr("data-country-name"));
    $(globeGridContainerDiv)
      .find(".country-information .description")
      .html($(clickedButton).attr("data-description"));
    $(globeGridContainerDiv)
      .find(".country-information > a")
      .attr(
        "href",
        $(clickedButton).attr("data-country-name") === "Australia"
          ? "/about-us/locations-in-australia/"
          : "/about-us/global-locations/#" +
              $(clickedButton)
                .attr("data-country-name")
                .toLowerCase()
                .replace(/ /g, "-")
      );
    $(globeGridContainerDiv).find(".country-information").fadeIn(300);
  }

  /*---------------------------------- HISTORY TIMELINE ----------------------------------*/
  let centerPadding =
    $(document).innerWidth() > 1110 ? ($(document).innerWidth() - 1110) / 2 : 0;

  $("#history-timeline").slick({
    centerMode: true,
    centerPadding: centerPadding + "px",
    fade: false,
    speed: 1000,
    autoplayspeed: 2000,
    autoplay: true,
    infinite: false,
    cssEase: "ease",
  });

  var nodesSlidesWithSameYear = $('a.year[data-gotoslide*=","]');
  var slidesWithSameYear = [];

  var _iterator = _createForOfIteratorHelper(nodesSlidesWithSameYear),
    _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done; ) {
      slidesWithSameYear.push(
        _step.value.getAttribute("data-Gotoslide").split(",")
      );
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  $("a[data-gotoslide]:first-child").addClass("active");

  $("a[data-gotoslide]").click(function (e) {
    e.preventDefault();
    var slideIndex = $(this).data("gotoslide");

    if (slideIndex.toString().includes(",")) {
      slideIndex = slideIndex.slice(0, slideIndex.indexOf(","));
    }

    $("#history-timeline").slick("slickGoTo", slideIndex);
  });

  $("#history-timeline").on(
    "beforeChange",
    function (event, slick, currentSlide, nextSlide) {
      $("a.year[data-gotoslide]").removeClass("active");
      var slideIndex = nextSlide;
      var slideIndexes = slidesWithSameYear.find(function (indexes) {
        return indexes.includes(slideIndex.toString());
      });

      if (slideIndexes) {
        $('a.year[data-gotoslide="' + slideIndexes.join(",") + '"]').addClass(
          "active"
        );
      } else {
        $('a.year[data-gotoslide="' + slideIndex + '"]').addClass("active");
      }
    }
  );

  /*---------------------------------- HOMEPAGE SLICK SLIDER ----------------------------------*/

  $(".homepage-slider").slick({
    fade: true,
    speed: 1000,
    infinite: true,
    autoplayspeed: 5000,
    autoplay: true,
    cssEase: "ease",
  });

  /*---------------------------------- LOCATION IN AUSTRALIA ----------------------------------*/

  $("#location-in-au-map").imageMapResize();
  var states = ["wa", "nsw", "nt", "sa", "qld", "tas", "vic"];
  states.forEach(function (state) {
    $("." + state + "-map-area").hover(
      function () {
        // over
        $("#" + state + "-map").fadeIn(1);
      },
      function () {
        // out
        if (!$("." + state + "-map-area:hover").length) {
          $("#" + state + "-map").fadeOut(1);
        }
      }
    );
    $("." + state + "-map-area.map-btn").click(function (e) {
      e.preventDefault();

      if (!$("#" + state + "_map_blurb_container").hasClass("state-selected")) {
        $("#map-au-inner-container").addClass("state-selected");

        if (
          $(
            ".map-blurb-inner-container.state-selected:not(#" +
              state +
              "_map_blurb_container)"
          ).length
        ) {
          $(
            ".map-blurb-inner-container.state-selected:not(#" +
              state +
              "_map_blurb_container)"
          ).fadeOut(200, function () {
            $(
              ".map-blurb-inner-container.state-selected:not(#" +
                state +
                "_map_blurb_container)"
            ).removeClass("state-selected");
            $("#" + state + "_map_blurb_container")
              .addClass("state-selected")
              .fadeIn(300);
          });
        } else {
          $("#" + state + "_map_blurb_container")
            .addClass("state-selected")
            .fadeIn(300);
        }
      } else {
        $("#" + state + "_map_blurb_container")
          .removeClass("state-selected")
          .fadeOut(200);
        $("#map-au-inner-container").removeClass("state-selected");
      }
    });
  });

  /*---------------------------------- NPT Map ----------------------------------*/

  $("#npt-map").imageMapResize();
  $(".npt-map-area").click(function (e) {
    e.preventDefault();

    if ($("#npt-city-label").html() === $(this).attr("title")) {
      $("#npt-city-label").fadeOut(200);
      setTimeout(function () {
        $("#npt-city-label").html("");
      }, 500);
    } else {
      $("#npt-city-label").html($(this).attr("title"));
      $("#npt-city-label").fadeIn(300);
    }
  });

  /*---------------------------------- TAXI MAP ----------------------------------*/

  $("#taxi-map").imageMapResize();
  $(".taxi-map-area").click(function (e) {
    e.preventDefault();

    if ($("#taxi-city-label").html() === $(this).attr("title")) {
      $("#taxi-city-label").fadeOut(200);
      setTimeout(function () {
        $("#taxi-city-label").html("");
      }, 500);
    } else {
      $("#taxi-city-label").html($(this).attr("title"));
      $("#taxi-city-label").fadeIn(300);
    }
  });

  /*---------------------------------- HOMEPAGE SERVICES SEPARATOR ANIMATION ----------------------------------*/

  if ($(".home .service-container.aos-animate").length) {
    $(".home .services-container").addClass("animate-done");
  } else {
    $(document).on("aos:in:last-service-animate", function () {
      $(".home .services-container").addClass("animate-done");
    });
  }

  /* Subpages Sidebar Hover
  ========================================================================== */

  $("#subpages-sidebar li").on("mouseover", function (e) {
    e.stopPropagation();
    $(this).addClass("hover");
  });

  $("#subpages-sidebar li").on("mouseout", function () {
    $(this).removeClass("hover");
  });

  /* Team members profile
  ========================================================================== */

  $(".member").on("click", function () {
    $(".member-profile[data-member='" + $(this).data("member") + "']")
      .addClass("active")
      .css("display", "flex")
      .hide()
      .fadeIn();

    $("body").css("overflow-y", "hidden");
  });

  $(document).on(
    "click",
    ".member-profile.active .member-profile__close",
    function () {
      $(".member-profile.active").removeClass("active").fadeOut();
      $("body").css("overflow-y", "unset");
    }
  );

  $(document).on("click", ".member-profile.active", function (event) {
    if (this == event.target) {
      $(".member-profile.active").removeClass("active").fadeOut();
      $("body").css("overflow-y", "unset");
    }
  });

  /* Financial results filter
  ========================================================================== */

  $("#financial-results-year-select").on("change", function () {
    let yearSelected = $(this).val();
    $(".financial-results__year:visible").fadeOut(300, function () {
      $(`.financial-results__year[data-year="${yearSelected}"]`).fadeIn();
    });
  });

  /* Annual reports slider
  ========================================================================== */

  $(".annual-reports").slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "ease",
    responsive: [
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /* Announcements & reporting
  ========================================================================== */

  $("#announcements-year-select").on("change", function () {
    let yearSelected = $(this).val();
    $(".announcements-by-year:visible").fadeOut(300, function () {
      $(`.announcements-by-year[data-year="${yearSelected}"]`).fadeIn();
    });
  });

  $("#presentations-year-select").on("change", function () {
    let yearSelected = $(this).val();
    $(".presentations-by-year:visible").fadeOut(300, function () {
      $(`.presentations-by-year[data-year="${yearSelected}"]`).fadeIn();
    });
  });

  /* Financial calendars
  ========================================================================== */

  $("#financial-calendars-year-select").on("change", function () {
    let yearSelected = $(this).val();
    $(".financial-calendars-by-year:visible").fadeOut(300, function () {
      $(`.financial-calendars-by-year[data-year="${yearSelected}"]`).fadeIn();
    });
  });
});
